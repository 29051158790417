.mainContainer,
.flexCenter {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.enterVerificationCode {
  color: #777 !important;
  font-weight: 400 !important;
  text-align: center;
  font-size: 16px !important;
}

.headerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: max-content;
  padding-top: 32px;
  max-width: 960px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #0000000f;
}

@media only screen and (max-width: 800px) {
  .mainContainer {
    padding: 0 6px;
    overflow-x: hidden;
  }
}
