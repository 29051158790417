*,
body,
html {
  font-family: "Poppins", sans-serif;
}

body {
  min-width: 1280px;
  overflow-x: auto;
}

.responsive-width {
  min-width: unset !important;
  overflow-x: unset !important;
}

#app-sider {
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
  border: 1px solid #eee;
  overflow-x: hidden;
}

.sider-company-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 64px;
  padding: 6px;
  border-bottom: 1px solid #eee;
}

.sider-company-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 14px;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding-left: 4px;
  padding-right: 4px;
  transition: 250ms ease;
  cursor: pointer;
}

.sider-company-wrapper:hover {
  background-color: #f1f1f1;
  transition: 250ms ease;
}

.sider-company-name {
  font-size: 14px;
  font-weight: 600;
  color: #333;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
  width: 98px;
}

#sider .ant-menu-item:not(.ant-menu-item-active) {
  opacity: 0.75;
}

#sider .ant-menu-item {
  margin-bottom: 0px !important;
  height: 46px !important;
  line-height: 46px !important;
}

.sider-user-mail {
  font-size: 12px;
  opacity: 0.5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 124px;
  color: #333;
  cursor: default;
}

.containerWraperMinHeight {
  min-height: 100vh;
}

.siteHeader {
  background-color: white;
  margin: 12px 0 0 0;
  padding: px;
}

.columnsList {
  position: absolute;
  min-width: 210px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.15s ease-out;
  background-color: white;
  z-index: 12345679;
}

.IconDropDown {
  transition: transform 0.15s ease-out;
}

.ListItemCustomClass {
  cursor: pointer;
}

.spaceBetweenRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pointer:hover {
  cursor: pointer;
}

.displayNone {
  display: none !important;
}
.listTreeItem {
  padding: 3px;
  transition: 250ms ease;
}

.listItemHoverColor {
  border-radius: 4px;
  transition: 250ms ease;
}

.listItemHoverColor:hover {
  background-color: #b1c0d260;
  transition: 250ms ease;
}

.listTreeItem.active {
  background-color: white;
}
.ant-tree {
  background-color: transparent;
}

.userAssetCheckTree .ant-tree-treenode {
  padding: 0 0 16px 0;
  width: 100%;
  position: relative;
}

.locationCard {
  transition: 150ms ease;
  cursor: pointer;
  border-radius: 6px;
}

.locationCard:hover {
  background-color: #b1c0d23a;
  transition: 150ms ease;
}

.listItemHoverColor:hover .removeBtn {
  display: block;
}

.removeBtn {
  display: none;
}

.antdButtonWIthIcon {
  padding: 0 9px;
}

.activeRole {
  background-color: azure;
}

.flexRowCenterStart {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

input[type="color"] {
  -webkit-appearance: none;
  border: none !important;
  outline: none !important;
  width: 0 !important;
  height: 0 !important;
  padding: 0 !important;
}

input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  margin-top: 0px;
  cursor: pointer;
  user-select: none;
  outline: none !important;
  margin-left: 12px;
}

input[type="color"]::-webkit-color-swatch {
  border: none;
  border-radius: 40px;
  outline: none !important;
}

::-moz-color-swatch {
  outline: none !important;
}
.instructions {
  display: none;
}
.activeInstruction .instructions {
  display: flex;
}

#calendar-filters .ant-select,
#calendar-filters .ant-tree-select {
  height: 36px !important;
  border-radius: 0.25em !important;
}

#calendar-filters .ant-select div,
#calendar-filters .ant-tree-select div {
  height: 100% !important;
  border-radius: 0.25em !important;
}

#asset-details-spec .ant-spin-blur {
  width: 200px;
  height: 200px;
}

.site-layout-background-auth {
  overflow-x: hidden;
  background-color: #fff;
}

.grey-chip {
  background-color: #eee;
  color: #333;
  border-radius: 20px;
  padding: 5px 14px;
  font-size: 11px;
  line-height: 10px;
  font-weight: 600;
  cursor: default;
}

#tagInput {
  display: flex;
  flex-direction: row;
}
#tagInput div[role="presentation"] {
  width: 100% !important;
}

#tagInput .input-tag {
  padding: 5px 10px;
  height: 32px;
  border-radius: 4px !important;
  font-weight: 500;
  width: 100px !important;
}

#tagInput .tag,
#taskCard .tag {
  padding: 5px 10px;
  border-radius: 4px !important;
  background-color: #ccc;
  color: #434343;
  font-weight: 500;
  margin-bottom: 8px;
}

#tagInput .tag svg {
  margin-left: 10px;
}

#taskForm .ant-upload {
  width: 100% !important;
}

#taskCard .ant-card {
  transition: 600ms ease !important;
}

#openOrder .tag {
  padding: 5px 12px;
  border-radius: 4px !important;
  background-color: #eee;
  color: #434343;
  font-weight: 500;
}

.modal-preview-header {
  display: flex;
  flex-direction: column;
}

.modal-preview-header--wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.modal-preview-header--name {
  font-size: 24px;
}

/* Dashboard cards */
.dashboard-card {
  border: 1px solid #dedede !important;
  /* box-shadow: 0px 0px 10px 2px #00000015 !important; */
  transition: 250ms ease;
}

.dashboard-card:hover {
  background-color: #f8f8f8 !important;
  transition: 250ms ease;
}

.white-container {
  padding: 8px 12px;
  background-color: white;
  border: 1px solid #dedede;
  /* box-shadow: 0px 0px 10px 2px #00000015; */
  border-radius: 4px;
}

.placeholder {
  border: 1px solid gray;
  padding: 7px 10px;
  border-radius: 3px;
  color: gray;
  min-width: 200px;
  text-align: left;
  font-size: 0.9em;
}

.placeholderWrap {
  display: flex;
}

.placeholderWrap .placeholder:last-child {
  margin-left: 10px;
}

.stoposto {
  width: 100%;
}

/* Notifications */
#header sup {
  border: 2px solid #001529 !important;
  border-radius: 20px;
  min-width: 24px;
  min-height: 24px;
  box-shadow: unset !important;
}

#notifications .ant-list-item-meta-title {
  margin-bottom: 4px !important;
}

#notifications .ant-list-item-meta {
  margin-bottom: 8px !important;
}

::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 5px #0353ef;
  background: rgba(0, 0, 0, 0.2);
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}

/* Pin Field */
.pin-field {
  width: 75px;
  height: 75px;
  font-size: 40px;
  text-align: center;
  outline: none;
  border-radius: 5px;
  border: 1px solid #d3d3d3;
  transition-property: color, border, box-shadow, transform;
  transition-duration: 250ms;
  margin-right: 10px;
}

.pin-field:last-child {
  margin-right: 0px;
}

.pin-field:focus {
  outline: none;
  box-shadow: 0 0 7px #0353ef80;
  border: 1px solid #0353ef;
  transform: scale(1.025);
}

.pin-field-complete {
  border: 1px solid #6ab04c;
  color: #6ab04c;
}
.pin-field-complete[disabled] {
  background: #6ab04c;
  opacity: 0.5;
  cursor: not-allowed;
}

.partners-table-status-waiting {
  background-color: #f8f8f8;
}

.timer-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.timer-wrapper span {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex: 0 1 25%;
  -ms-flex: 0 1 25%;
  flex: 0 1 25%;
  font-size: 18px;
  background-color: #404549;
  border-radius: 3px;
  padding: 4px 8px;
  color: white;
}

.timer-wrapper span::after {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 50%;
  bottom: 50%;
  content: "";
  width: "100%";
  height: 2px;
  background-color: #232323;
  opacity: 0.2;
}

.timer-wrapper div {
  font-size: 18px;
  padding: 0 1px;
}

.permissionsTree .ant-tree .ant-tree-treenode {
  margin: 12px 0;
}

/* Maintenance spec */
#maintenance-spec .ant-select-selector,
#maintenance-spec .ant-picker {
  border: none;
  border-radius: 0px;
  padding-left: 16px;
}

#maintenance-spec td {
  padding: 0px !important;
}

#maintenance-spec td:not(.select) {
  padding: 8px 16px !important;
}

.time-input {
  width: 24px;
  height: 24px;
  font-size: 14px;
  text-align: center;
  outline: none;
  border-radius: 3px;
  border: 1px solid #d3d3d3;
  transition-property: color, border, box-shadow, transform;
  transition-duration: 250ms;
  margin-right: 4px;
}

.time-input:last-child {
  margin-right: 0px;
}

.time-input:focus {
  outline: none;
  box-shadow: 0 0 7px #0353ef80;
  border: 1px solid #0353ef;
  transform: scale(1.025);
}

#duration-input .ant-input-number-group-wrapper,
#duration-input input {
  height: 32px !important;
}

#duration-input .ant-input-number {
  height: 32px !important;
}

#duration-input .ant-input-number-group-addon {
  border: none;
}

#duration-input .ant-row.ant-form-item {
  margin-bottom: 0 !important;
}

.fc-toolbar-title {
  font-size: 1.35em !important;
}

#sider-component .ant-menu-title-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#bordered-tabs .ant-tabs-nav::before {
  border-bottom: 1px solid #dedede !important;
}

.custom-page-header .ant-descriptions-item-container {
  align-items: center;
}

.custom-page-header .ant-descriptions-item-label {
  opacity: 0.6;
  font-size: 15px;
}

.custom-page-header .ant-descriptions-item-content {
  font-weight: 600;
  font-size: 15px;
  color: #333;
}

.custom-page-header .ant-select-selection-item {
  font-weight: 600;
  font-size: 15px;
  color: #0353ef;
}

.custom-page-header .main-tag,
.main-tag {
  transform: translateY(-5px);
  padding: 4px 16px;
  border: none;
  border-radius: 40px;
  background-color: #000;
  color: #fff;
  font-weight: 500;
}

.custom-page-header .tag-chip {
  padding: 2px 10px;
  border: none;
  border-radius: 40px;
  background-color: #ccc;
  color: #333;
  font-weight: 400;
}

.custom-page-header .ant-page-header-heading-extra {
  display: flex;
  flex-direction: row;
}

.custom-page-header .ant-typography-edit-content {
  margin-bottom: 0px;
  margin-left: 12px;
}

.custom-page-header .ant-typography-edit-content .ant-input {
  background-color: transparent;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
}

.custom-page-header .ant-descriptions-item-container {
  height: 27px;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.qr-code-modal {
  /* style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0, border: "2px dashed #0353ef", borderRadius: 200 }} */
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
  position: absolute;
  top: 0;
  left: 0;
  border: 2px dashed #0353ef;
  border-radius: 200px;
  animation: qr-code-rotation 60s infinite linear;
}

@keyframes qr-code-rotation {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(359deg);
  }
}

.qr-code-modal--divider {
  width: 100%;
  height: 100%;
}

.settings-tag {
  padding: 5px 10px;
  border-radius: 4px !important;
  background-color: #ccc;
  color: #434343;
  font-weight: 500;
  margin-bottom: 8px;
}

.userAssetCheckTree .ant-tree-list-holder-inner {
  position: relative !important;
}

.userAssetCheckTree .ant-tree-checkbox {
  position: absolute !important;
  top: 6px;
  right: 0px;
}

.userAssetCheckTree .ant-tree-switcher {
  margin-top: 4px;
}

.userAssetCheckTreeRadioGroup {
  position: absolute !important;
  top: 6px;
  right: 0px;
}

.role-tabs .ant-typography-edit {
  float: right !important;
}

.role-tabs .ant-tabs-tab {
  width: 480px !important;
}

.role-tabs .ant-tabs-tab h5 {
  width: 280px !important;
}

/* Custom input */
.custom-input {
  border-bottom: 1px solid #d9d9d9 !important;
  border-radius: 0 !important;
}

.ant-btn.ant-btn-link.ellipsis span {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.maintenancesAnalyticsTabs .ant-tabs-nav-more {
  display: none !important;
}
.ant-popover-inner-content {
  min-width: 200px;
}

.auth-header {
  background: white !important;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 20px !important;
}

@media only screen and (max-width: 1200px) {
  .auth-header {
    justify-content: flex-end;
  }
}

body.ant-popover-open .ant-popover.ant-popconfirm {
  z-index: 16786780000;
}
.ant-popover {
  width: 369px;
}

.dateDynamicColumns .ant-picker-input > input-disabled,
.ant-picker-input > input[disabled] {
  color: #000;
}

#files-modal .slick-slide {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rrule-component ::-webkit-scrollbar {
  width: 0 !important; /* Remove scrollbar space */
  height: 0 !important; /* Remove scrollbar space */
  background: transparent !important;
}

.fc .fc-popover-body {
  max-height: 200px;
  max-width: 420px;
  overflow-y: auto;
}
