.filesContainer {
  transition:
    opacity 300ms ease,
    height 450ms ease;
}

.cardContainer {
  background-color: #fff;
  cursor: pointer;
  padding: 16px;
  padding-top: 12px;
  border-radius: 8px;
  border: 1px solid #ddd;
  min-width: 260px;
}

.cardContent {
  padding: 16px;
}

.cardTitle {
  /* white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
  transform: translateY(4px);
  user-select: none;
}

.cardType {
  font-size: 14px;
  font-weight: 500;
  opacity: 0.5;
  white-space: pre-wrap;
}

.cardActions {
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.cardActionItem {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
