.container {
  min-height: 80vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
}

.listContainer {
  max-height: calc(80vh - 304px);
  overflow-y: auto;
}

.stepsContent {
  min-height: 200px;
  height: 100%;
  margin-top: 24px;
  padding: 16px;
  text-align: center;
  background-color: #fafafa;
  border: 1px dashed #e9e9e9;
  border-radius: 2px;
  flex: 1;
}

.stepsAction {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
}

.stepOneHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 14px;
  padding-bottom: 0px;
}

.stepOneListItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  width: 100%;
  position: relative;
  padding-left: 16px;
}

.stepOneListItemCheckboxWrapper {
  width: 100%;
  padding-left: 12px;
}

.stepOneListItemText {
  width: 100%;
  margin-right: 20px;
}

.stepOneListItemImage {
  width: 24px;
  height: 24px;
  border-radius: 4px;
}
